import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  imageWrapper: {
    width: '100%',
    height: '250px',
    position: 'relative',
    overflow: 'hidden',
  },
  heroImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  caption: {
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function HeroHeader() {
  const classes = useStyles();
  const headerImage = "./assets/step_image.jpeg";

  return (
    <Paper className={classes.heroContainer} elevation={2}>
      <div className={classes.imageWrapper}>
        <img
          src={headerImage}
          alt="Person climbing steps"
          className={classes.heroImage}
        />
      </div>
      <div className={classes.caption}>
        <Typography variant="caption">
          Photo by{' '}
          <a
            href="https://unsplash.com/@bruno_nascimento"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Bruno Nascimento
          </a>
          {' '}on{' '}
          <a
            href="https://unsplash.com/s/photos/step"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Unsplash
          </a>
        </Typography>
      </div>
    </Paper>
  );
}

export default HeroHeader; 