import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <Box component="footer" className={classes.footer}>
      <Typography variant="body2" color="textSecondary">
        © {currentYear} All rights reserved.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Crafted with ☕ by{' '}
        <Link
          href="https://coffeebytez.com"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          Coffee Bytez
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer; 