import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import { Menu as MenuIcon, DirectionsRun } from "@material-ui/icons";
import { Link } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  toolbar: {
    padding: theme.spacing(1, 0),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logo: {
    marginRight: theme.spacing(1),
    fontSize: '2rem',
    color: '#fff',
  },
  title: {
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktopMenu: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& .MuiPaper-root': {
      maxWidth: '100%',
      width: 'auto',
    },
  },
  navButton: {
    margin: theme.spacing(0, 1),
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  activeNavButton: {
    borderBottom: '2px solid #fff',
  },
  menuItem: {
    minWidth: 150,
    maxWidth: '100vw',
  },
  mobileMenuContainer: {
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function StepAppBar() {
  const classes = useStyles();
  const pages = [
    {
      pageName: 'Stepbet Calculator',
      page: '/stepbetcalculator',
    },
    {
      pageName: 'BMI Calculator',
      page: '/bmicalculator',
    },
    {
      pageName: 'About',
      page: '/about',
    },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const currentPath = window.location.pathname;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth="md">
        <Toolbar disableGutters className={classes.toolbar}>
          {/* Logo and Brand Name */}
          <Link to="/" className={classes.logoContainer}>
            <DirectionsRun className={classes.logo} />
            <Typography variant="h6" className={classes.title}>
              StepBet Tools
            </Typography>
          </Link>

          {/* Desktop Navigation */}
          <Box className={classes.desktopMenu}>
            {pages.map((currentPage) => (
              <Button
                key={currentPage.page}
                component={Link}
                to={currentPage.page}
                className={`${classes.navButton} ${
                  currentPath === currentPage.page ? classes.activeNavButton : ''
                }`}
              >
                {currentPage.pageName}
              </Button>
            ))}
          </Box>

          {/* Mobile Menu Icon - Now in a container that's aligned right */}
          <Box className={classes.mobileMenuContainer}>
            <IconButton
              className={classes.menuButton}
              onClick={handleOpenNavMenu}
              color="inherit"
              edge="end"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Mobile Navigation Menu */}
          <Menu
            anchorEl={anchorElNav}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            className={classes.mobileMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {pages.map((currentPage) => (
              <MenuItem
                key={currentPage.pageName}
                component={Link}
                to={currentPage.page}
                onClick={handleCloseNavMenu}
                className={classes.menuItem}
                selected={currentPath === currentPage.page}
              >
                <Typography>{currentPage.pageName}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
