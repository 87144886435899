import React from "react";
import './App.css';
import {makeStyles} from '@material-ui/core/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import StepAppBar from "./components/StepAppBar";
import StepBetCalculator from "./components/StepBetCalculator";
import FitnessSales from "./components/FitnessSales";
import SaveOfflineButton from "./components/SaveOfflineButton";
import Footer from './components/Footer';
import HeroHeader from './components/HeroHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    content: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    mainContainer: {
        width: '100%',
        maxWidth: theme.breakpoints.values.md,
        margin: '0 auto',
        padding: theme.spacing(0, 2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
}));

function App() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <StepAppBar/>
                <div className={classes.mainContainer}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <HeroHeader />
                        </Grid>
                        
                        <StepBetCalculator />
                        
                        <Grid item xs={12}>
                            <FitnessSales />
                        </Grid>

                        <Grid item xs={12}>
                            <SaveOfflineButton />
                        </Grid>
                    </Grid>
                </div>
                
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            <Footer />
        </div>
    );
}

export default App;
